import React from 'react';
import Navbar from "../components/Navbar";

const Home = () => {
  return 
    <div>
      <Navbar />
    </div>;
 
};

export default Home;

